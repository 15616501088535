<template>
    <div class="page page-about">
        <div class="about-content">
            <div class="item">
                <div class="title">About Us</div>
                <div class="info">
                    We are a team of experienced games and defi experts with a passion for bringing game-changing concepts to the entire blockchain industry.
                </div>
            </div>
            <div class="item">
                <div class="title">Our Vision</div>
                <div class="info">
                    Build new economic and creative opportunities for people around the world which takes more than just gaming experience.
                </div>
            </div>
            <div class="item">
                <div class="title">Our Mission</div>
                <div class="info">
                    Becoming the global leader in blockchain gaming and bringing an innovative gaming experience to the world's gamers.
                </div>
            </div>
        </div>
<!--        <div class="about-form">-->
<!--            <div class="title">Get In Touch</div>-->
<!--            <div class="tips">-->
<!--                Want to keep updated on our latest blockchain games? <br />-->
<!--                Stay connected!-->
<!--            </div>-->
<!--            <div class="form">-->
<!--                <div class="item">-->
<!--                    <label>Name</label>-->
<!--                    <input />-->
<!--                </div>-->
<!--                <div class="item">-->
<!--                    <label>Your email address</label>-->
<!--                    <input />-->
<!--                </div>-->
<!--                <div class="button">-->
<!--                    <button>Subscribe</button>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
    </div>
</template>

<script>
export default {
    name: 'About'
}
</script>

<style lang="less">
@import "../../less/main.less";
.page-about {
    .about-content {
        width: 600px;
        margin: 0 auto;
        @media @mobile {
            width: 100%;
        }
        .item {
            padding: 36px;
            .title {
                padding-bottom: 36px;
                font-size: 24px;
                text-align: center;
                font-family: GothamBold;
            }
            .info {
                text-align: center;
                font-size: 18px;
                line-height: 28px;
                h2, p {
                    text-align: left;
                }
            }
        }
    }
    .about-form {
        width: 100%;
        background-color: #0F0F0F;
        margin: 0 auto;
        padding: 48px 0;
        .title {
            text-align: center;
            font-size: 24px;
        }
        .tips {
            text-align: center;
            padding: 24px 0;
        }
        .form {
            width: 300px;
            margin: 0 auto;
            .item {
                border-bottom: solid 1px #3F3F3F;
                display: flex;
                margin-bottom: 18px;
                label {
                    color: #353535;
                    padding-right: 12px;
                }
                input {
                    background-color: transparent;
                    flex: 1;
                    color: #fff;
                    &:focus {
                        border: none!important;
                        outline: none!important;
                    }
                }
            }
            .button {
                display: flex;
                padding: 24px 0;
                justify-content: center;
                button {
                    background-color: #008900;
                    padding: 3px 24px;
                    font-size: 16px;
                    color: #fff;
                    border-radius: 5px;
                }
            }
        }
    }
}
</style>
